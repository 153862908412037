import _ from 'lodash';
import { HashId } from "@cargo/hash-id";
import { FRONTEND_DATA, CRDTState, YTransactionTypes, PublishState } from "../../globals";
import { helpers } from "@cargo/common";
import { pinDefaults } from "../../defaults/pin-defaults";
import { overlayDefaults } from "../../defaults/overlay-defaults";
import { store } from "../../index";
import { API_ORIGIN } from "@cargo/common";
import getSlug from "@cargo/slug-generator";
import { getCRDTItem, applyChangesToYType } from "../multi-user/redux";
import { deleteMedia, getInUsePageMedia } from "../media";
import { ydoc } from "../multi-user";
import axios from 'axios';
import { actions } from "../../actions";
import * as Sentry from "@sentry/browser";

import { Observable } from 'lib0/observable';
import selectors from "../../selectors";
import { buildFlatContentList } from "../../components/top-menu-bar/sortable-tree/utilities";

import { globalUndoManager } from "../undo-redo";

let liveSorts = Promise.resolve();

if(ydoc.get('draft-store').get('structure')?.get('initialized') !== true) {
	liveSorts = axios.get(`${API_ORIGIN}/pages/${store.getState().site.id}/sorts`, {
		use_auth: true
	});
}

window.logSiteStructure = () => {

	const { CRDTItem: sortMapCRDT } = getCRDTItem({
		reducer: 'structure',
		item: 'bySort',
		readOnly: true
	});

	const { CRDTItem: indexMapCRDT } = getCRDTItem({
		reducer: 'structure',
		item: 'indexById',
		readOnly: true
	});

	if(!sortMapCRDT) {
		console.log('Site structure not initialized yet...');
		return {};
	}

	const sortMap = sortMapCRDT.toJSON();
	const idList = Object.keys(sortMap);
	const state = store.getState();

	idList.sort((a,b) => {
		return sortMap[a] - sortMap[b]
	});

	const tableData = [];

	idList.forEach(id => {

		const model = state.pages.byId[id] || state.sets.byId[id];

		tableData.push({
			id,
			parent: state.sets.byId[selectors.getItemParentId(state, id)]?.title,
			type: model?.page_type || 'Unknown',
			title: model?.title || 'Page not loaded',
			sort: sortMap[id],
			index: indexMapCRDT.get(String(id))
		});

	})

	console.table(tableData)

	return tableData

}

window.restripeSortIndex = (dryRun = true) => {

	restripe(dryRun).then(() => {
		hasSortIssue = false;
	});

}

let checkingSort = false;
let hasSortIssue = false;
const checkSorts = () => {

	if(hasSortIssue || checkingSort) {
		return;
	}

	// don't run this more than once at the same time
	checkingSort = true;

	restripe(true).then(() => {
		hasSortIssue = false;
	}).catch(() => {

		hasSortIssue = true;

		const error = new Error('Page list is incorrect');

		// log into the console
		console.error(error);

		// wait a beat to verify the page list is still broken
		setTimeout(() => {

			// test again. If still failing, report it.
			restripe(true, true).catch(() => {

				// automatically fix the issue
				restripeSortIndex(false);

				// log the structure for debugging purposes
				Sentry.captureException(error, {
					extra: {
						structure: window.logSiteStructure()
					}
				});
			})

		}, 200);

	}).finally(() => {
		checkingSort = false;
	});

}

// check sorts after undoing and redoing
globalUndoManager.on('yjs-stackitem-applied', () => {

	checkSorts();

});

export const restripe = async (dryRun = true, silent = false) => {

	await initializeSiteStructure();

	const lastIndexByParent = {};
	const state = store.getState();

	let foundIssues = false;

	if(!state.adminState.hasAdminList) {
		console.log('Unable to restripe without admin list');
		return foundIssues;
	}

	ydoc.transact(() => {

		const { CRDTItem: sortMapCRDT } = getCRDTItem({
			reducer: 'structure',
			item: 'bySort'
		});

		const { CRDTItem: parentMapCRDT } = getCRDTItem({
			reducer: 'structure',
			item: 'byParent'
		});

		const { CRDTItem: indexMapCRDT } = getCRDTItem({
			reducer: 'structure',
			item: 'indexById'
		});

		// get page list as used by the sortable tree
		buildFlatContentList(
			selectors.getSortedAdminListByParent(state)
		).forEach(({id, parentId : expectedParent}, expectedSort) => {

			const currentSort = sortMapCRDT.get(id);
			const currentIndex = indexMapCRDT.get(id);
			const currentParent = selectors.getItemParentId(state, id)
			let expectedIndex = null;

			const content = state.pages.byId[id] || state.sets.byId[id];

			if(content ? helpers.contentIsIndexable(content) : currentIndex !== null) {

				if(!lastIndexByParent.hasOwnProperty(expectedParent)) {
					lastIndexByParent[expectedParent] = -1;
				}

				expectedIndex = ++lastIndexByParent[expectedParent];

			}

			// console.log(id, {
			// 	currentSort,
			// 	expectedSort,
			// 	currentIndex,
			// 	expectedIndex,
			// 	currentParent,
			// 	expectedParent
			// })

			if(expectedSort !== currentSort) {
				console.log(id, 'sort is incorrect. Expected', expectedSort, 'got', currentSort);
				if(!dryRun) {
					sortMapCRDT.set(id, expectedSort);
				}
				foundIssues = true;
			}

			if(expectedIndex !== currentIndex) {
				console.log(id, 'index is incorrect. Expected', currentIndex, 'got', expectedIndex);
				if(!dryRun) {
					indexMapCRDT.set(id, expectedIndex)
				}
				foundIssues = true;
			}

			if(expectedParent !== currentParent) {
				console.log(id, 'parent is incorrect. Expected', expectedParent, 'got', currentParent);
				foundIssues = true;
			}

		});

		// set page_counts on sets using the amount of indexable
		// items we found
		_.each(lastIndexByParent, (lastIndex, id) => {

			const expectedPageCount = lastIndex + 1;
			const currentPageCount = state.sets.byId[id]?.page_count;

			if(expectedPageCount !== currentPageCount ) {
				console.log(id, 'page count is incorrect. Expected', expectedPageCount, 'got', currentPageCount);

				if(!dryRun) {
					const { CRDTItem: setCRDTItem } = getCRDTItem({
						reducer: 'sets.byId',
						item: id
					});

					setCRDTItem.set('page_count', expectedPageCount);
				}
				foundIssues = true;

			}

		})

	}, YTransactionTypes.NotUndoable);

	if(foundIssues) {

		if(dryRun === true) {
			throw 'Found issues'
		}

		return 'Found issues. Run again to see if things got fixed.';
	}

	return 'Looks good'

}

export const pageListEvents = new (class PageListEvents extends Observable {

	constructor () {
		super()
	}

});

export const getDefaultEditingPID = async () => {

	const state = store.getState();

	if(!state.frontendState.hasSiteModel) {
		return undefined;
	}

	const homepageId = selectors.getHomepageId(state);

	if(homepageId !== null) {

		const homepageModel = state.pages.byId[homepageId] || state.sets.byId[homepageId];

		if(!homepageModel) {

			const model = await store.dispatch(actions.fetchContent(homepageId)).catch(() => {});

			if(model && model.crdt_state !== CRDTState.Deleted) {
				return homepageId;
			}

		} else {

			// if the homepage is a regular page we want to edit it directly
			if(homepageModel.page_type === "page" && homepageModel.crdt_state !== CRDTState.Deleted) {

				return homepageId;

			} else if(homepageModel.stack === true) {

				// if homepage is a stack, edit it's first page
				const firstPageIdInSet = await getFirstPageInSet(homepageId, {
					indexable: true
				});

				// if the stack contains a valid page to edit, return it.
				if(firstPageIdInSet !== undefined) {
					return firstPageIdInSet;
				}

			}

		}

	}

	// still nothing. Just edit the first page we have even if it's a pin
	return await getFirstPageInSet('root', {
		indexable: false
	});

}

let lastPublishedAt;
// store listener to kill live Purl cache when a publish occurred (and the live data has been updated)
store.subscribe(() => {

	const publishedAt = store.getState().adminState.crdt.publishedAt;

	if(
		publishedAt !== lastPublishedAt
	) {
		livePurls = null;
	}

	lastPublishedAt = publishedAt;

});

const PURL_BLACKLIST=['backdrop','admin','editor','_api','inspector','freight','_api','auth','static','adminedit','edit','login','logout','search','upgrade','plan','delete','contact-form','img','dispatch','reset','404','apicore','apidesign','domainconfig','inlineadmin','following','followers','cart','.htaccess','_css','_gfx','_js','_jsapps','assets','videoplayer','crossdomain.xml','example','favicon.ico','robots.txt','favicon','media_temp', 'pid']
let livePurls = null;

const getExistingPURLs = async () => {

	await initializeSiteStructure();

	const {CRDTItem: setsInCRDT} = getCRDTItem({
		reducer: 'sets.byId',
		readOnly: true
	})

	const {CRDTItem: pagesInCRDT} = getCRDTItem({
		reducer: 'pages.byId',
		readOnly: true
	})

	if(!livePurls) {

		const liveData = await axios.get(`${API_ORIGIN}/pages/${store.getState().site.id}/sorts`, {
			use_auth: true
		})

		livePurls = liveData.data.reduce((map, content) => {
			map[content.purl] = content.id;
			return map;
		}, {});

	}

	// Get all live purls
	let allPURLs = _.map(livePurls, (id, purl) => {
		
		if(
			pagesInCRDT.get(String(id))?.get('crdt_state') === CRDTState.Deleted
			|| setsInCRDT.get(String(id))?.get('crdt_state') === CRDTState.Deleted
		) {
			// don't index purls of pages that have been deleted
			return null;
		}

		return purl.toLowerCase();

	});

	// add blacklist
	allPURLs = allPURLs.concat(PURL_BLACKLIST);

	// add all purls for draft pages / sets
	setsInCRDT.forEach(item => item.get("crdt_state") !== CRDTState.Deleted && allPURLs.push(item.get("purl")?.toLowerCase()));
	pagesInCRDT.forEach(item => item.get("crdt_state") !== CRDTState.Deleted && allPURLs.push(item.get("purl")?.toLowerCase()));

	return allPURLs;

}

export const updateContentTitle = async (contentType, contentId, newTitle = "Untitled Page") => {

	if(contentType !== "page" && contentType !== "set") {
		throw 'updateContentTitle: contentType parameter must be "set" or "page"'
	}

	newTitle = newTitle.trim();

	// check if title is changed at all
	const existingContent = store.getState()[`${contentType}s`].byId[contentId];

	if(!existingContent || existingContent.title === newTitle) {
		// same title, don't do anyting.
		return;
	}

	globalUndoManager.pause();

	const { CRDTItem: draftContent } = getCRDTItem({
		reducer: `${contentType}s.byId`,
		item: contentId
	});

	// grab all existing slugs so we don't collide
	const existingPURLs = await getExistingPURLs();

	ydoc.transact(() => {
		draftContent.set('title', newTitle);
		draftContent.set('purl', getSlug(newTitle, {inUse: existingPURLs}));
	});

	globalUndoManager.resume();

}

const generatePageHashId = async () => {
	const state = store.getState()
	const siteId = state.site.id
	const userId = state.user?.id ?? 0
	await HashId.enableCompression()
	await HashId.enablePrefix()
	const hashIdGenerator = HashId.generator(siteId, userId)

	return hashIdGenerator.next().value
}
	
export const createNewContent = async (type, title, parentId = 'root') => {

	console.log('create new', type);

	await initializeSiteStructure();

	globalUndoManager.pause();

	let contentId = null;

	try {

		// if a title was passed, use it — otherwise make it 'Untitled (Type)'
		title = title ? title : 'Untitled ' + type.charAt(0).toUpperCase() + type.slice(1);

		// Get unique id
		contentId = await generatePageHashId()

		// grab all existing slugs so we don't collide
		const existingPURLs = await getExistingPURLs();

		// Check incoming purl against existing purls to prevent any collisions
		const PURL = getSlug(title, {inUse: existingPURLs})

		// by default new content is inserted at the top of the list
		let customSortIndex = 0;

		// create an empty map our new content will live in
		const newContentModel = new Y.Map();

		if(type === "page") {

			const {CRDTItem: pagesInCRDT} = getCRDTItem({
				reducer: 'pages.byId',
				readOnly: true
			})

			// Set the page, with defaults, in the CRDT
			pagesInCRDT.set(contentId, newContentModel);

			// Populate the page with defaults
			applyChangesToYType(newContentModel, {
				"id": contentId,
				"title": title,
				"crdt_state": CRDTState.New,
				"purl": PURL,
				"page_type": "page",
				"display": true,
				"password_enabled": false,
				"content": "",
				"media": [],
				"local_css": null,
				"backdrops": {
					activeBackdrop: "none"
				},
				"pin": false,
				"pin_options": {},
				"overlay": false,
				"overlay_options": {},
				// tags
				"tags": [],
				"tags_with_links": null,
				"thumb_is_visible": true,
				"thumb_images_id": null,
				"thumb_media_id": null,
				"thumb_meta": null,
				//*** "design options" (not currently used anywhere)
				"page_design_options": null
			});

		} else if(type === "set") {

			const {CRDTItem: setsInCRDT} = getCRDTItem({
				reducer: 'sets.byId',
				readOnly: true
			})

			// Add the set to the CRDT
			setsInCRDT.set(contentId, newContentModel);

			// Populate the set with defaults
			applyChangesToYType(newContentModel, {
				"id": contentId,
				"title": title,
				"purl": PURL,
				"page_type": "set",
				"display": true,
				"crdt_state": CRDTState.New,
				"stack": false,
				"backdrop_enabled": false,
				"password_enabled": false,
				"thumb_is_visible": true,
				"page_count": 0,
				"thumb_meta": null
			});

			// Closed new set by default
			updateClosedSetsList('add', contentId)

			// create an entry in the byParent structure reducers
			const { CRDTItem: structureByParentCRDT } = getCRDTItem({
				reducer: 'structure',
				item: 'byParent'
			});

			structureByParentCRDT.set(contentId, new Y.Array());

		}

		// if we're inserting the page into a specific set, insert it
		// as the first child of that set
		if(parentId !== 'root') {

			const { CRDTItem: sortMapCRDT } = getCRDTItem({
				reducer: 'structure',
				item: 'bySort'
			});

			if(sortMapCRDT.has(parentId)) {
				customSortIndex = sortMapCRDT.get(parentId) + 1;
			} else {
				console.error('unable to insert new content into', parentId);
				// default back to inserting at the top of the list
				parentId = 'root';
			}

		}

		// insert the content in to the site's structure by providing `toItem` and `toParent`
		// arguments only. This'll result in a insert operation
		await sortContent(
			undefined, 
			{
				id: contentId, 
				content: newContentModel.toJSON()
			}, 
			undefined, 
			parentId,
			// insert at the start of the new parent
			{
				customSortIndex
			}
		);

	} catch(e) {

		console.error(e);
		Sentry.captureException(e);

	}

	globalUndoManager.resume();

	pageListEvents.emit('new-content-created', [contentId]);

	return contentId;

}

export const deleteContent = async (type, id, options = {}) => {

	console.log('delete', type, id);

	await initializeSiteStructure();

	let reducer;

	if(type === 'page') {
		reducer = 'pages.byId';
	} else if(type === 'set') {
		reducer = 'sets.byId';
	} else {
		throw 'can only delete "page" or "set" types';
	}

	globalUndoManager.pause();

	// grab the content
	let { CRDTItem: contentToDelete } = getCRDTItem({
		reducer,
		item: id
	});

	if(!contentToDelete || contentToDelete.crdt_state === CRDTState.Deleted) {
		globalUndoManager.resume();
		return;
	}

	try {
		
		// serialize it for cheap access
		const serializedContentToDelete = contentToDelete.toJSON();
		const state = store.getState();

		// If this item is homepaged, un-homepage it first
		if(state.site.homepage_id === id) {
			await setHomePage(id, false, 'desktop', options);
		}

		if(state.site.mobile_homepage_id === id) {
			await setHomePage(id, false, 'mobile', options);
		}

		if(options.preventSort !== true) {

			// find parent of item we're deleting
			const parentId = selectors.getItemParentId(state, serializedContentToDelete.id);

			// before we can delete, we'll need to move all contents out of the set
			if(type === "set") {

				const { CRDTItem: parentMapCRDT } = getCRDTItem({
					reducer: 'structure',
					item: 'byParent',
					readOnly: true
				});

				const { CRDTItem: sortMapCRDT } = getCRDTItem({
					reducer: 'structure',
					item: 'bySort',
					readOnly: true
				});

				// first move all set contents out of this set
				const children = parentMapCRDT.get(id)?.toArray();

				// make sure the children are sorted correctly
				children.sort((a,b) => {
					return sortMapCRDT.get(a) - sortMapCRDT.get(b);
				});

				const deletedItemSortIndex = sortMapCRDT.get(serializedContentToDelete.id);

				for(let i = 0; i < children.length; i++) {

					const item = state.pages.byId[children[i]] || state.sets.byId[children[i]];

					await sortContent(
						// move the child
						{
							id: children[i], 
							content: item
						}, 
						// to before the set we're deleting
						{
							id: serializedContentToDelete.id, 
							content: serializedContentToDelete
						}, 
						// from it's current parent
						serializedContentToDelete.id, 
						// to the parent of the set we're deleting
						parentId
					);

				}

			}

			// remove the content from the site's structure by providing `fromItem` and `fromParent`
			// arguments only. This'll result in a delete operation
			await sortContent(
				{
					id: serializedContentToDelete.id, 
					content: serializedContentToDelete
				}, 
				undefined, 
				parentId, 
				undefined
			);

		}

		// set content to deleted
		contentToDelete.set('crdt_state', CRDTState.Deleted);

		// delete all media associated to this content
		serializedContentToDelete.media?.forEach(mediaItem => {
			deleteMedia({
				target: getCRDTItem({ reducer: reducer, item: id }),
				field: 'media'
			}, mediaItem);
		});

	} catch(e) {

		console.error(e);
		Sentry.captureException(e);

	}

	globalUndoManager.resume();

}

const createDuplicatedContent = async (originalContent, options = {}) => {

	if(!originalContent) {
		return;
	}
	
	const state = store.getState();

	// create a fresh object for the duplicated content
	const duplicatedContent =  _.cloneDeep(originalContent);

	if(duplicatedContent.page_type === "page") {
		
		// find active media on the page
		const inUseMedia = getInUsePageMedia(duplicatedContent);

		// filter out any media not in use
		duplicatedContent.media = duplicatedContent.media.filter(media => inUseMedia.includes(media.hash));

	}

	// Get unique id
	const newContentId = await generatePageHashId()

	// update title
	if(options.title) {
		
		duplicatedContent.title = options.title;

	} else {

		// just append copy
		duplicatedContent.title = duplicatedContent.title + ' copy'

		// make sure title is unique
		let counter = 1;
		let baseTitle = duplicatedContent.title;
		let titleIsInUse = true;

		// while the title of this duplicated page is already in use
		while(
			// get list of pages or sets based on what we're duplicating
			(duplicatedContent.page_type === "set" ? Object.values(state.sets.byId) : Object.values(state.pages.byId))
			// filter out deleted pages
			.filter(content => content.crdt_state !== CRDTState.Deleted)
			// get lowercase titles (sometimes page.title ends up undefined, not sure why.. https://cargo3.sentry.io/issues/4239358729/?project=4504992645578752&query=is%3Aunresolved&referrer=issue-stream&stream_index=2)
			.map(content => content.title?.toLowerCase())
			// see if it includes the title for the cloned content
			.includes(duplicatedContent.title.toLowerCase())
		) {

			duplicatedContent.title = baseTitle + ` ${counter}`
			counter++;

		}

	}

	// grab all existing slugs so we don't collide
	const existingPURLs = await getExistingPURLs();

	// Check copied purl against existing purls to prevent any collisions
	duplicatedContent.purl = getSlug(duplicatedContent.title, {inUse: existingPURLs});

	// find and replace old id in the local CSS
	if(typeof duplicatedContent.local_css === "string") {
		duplicatedContent.local_css = duplicatedContent.local_css.replaceAll(duplicatedContent.id, newContentId);
	}

	if(duplicatedContent.hasOwnProperty('page_count')) {
		// reset page count on sets
		duplicatedContent.page_count = 0;
	}

	// set crdt_state to "New"
	duplicatedContent.crdt_state = CRDTState.New;

	// finally, update the id
	duplicatedContent.id = newContentId;

	return duplicatedContent;

}

export const duplicateContent = async (id, options = {}) => {

	console.log('duplicate', id);

	await initializeSiteStructure();
	
	globalUndoManager.pause();

	try {

		const { CRDTItem: sortMapCRDT } = getCRDTItem({
			reducer: 'structure',
			item: 'bySort'
		});

		const state = store.getState();
		const originalContent = options.originalContent || state.pages.byId[id] || state.sets.byId[id];
		const parentId = options.customParentId || selectors.getItemParentId(state, originalContent.id) || 'root';

		// create a fresh object for the duplicated content
		const duplicatedContent = await createDuplicatedContent(
			originalContent, 
			options
		);

		if(!duplicatedContent) {
			throw 'Unable to duplicate ' + id;
		}

		// create a YJS type for the new page
		const newContentModel = new Y.Map();
		const targetReducer = duplicatedContent.page_type === "page" ? 'pages.byId' : 'sets.byId';

		ydoc.transact(() => {

			const {CRDTItem: targetCRDTReducer} = getCRDTItem({
				reducer: targetReducer,
				readOnly: true
			});

			// inject into the CRDT
			targetCRDTReducer.set(duplicatedContent.id, newContentModel);

			// populate the CRDT content map
			applyChangesToYType(newContentModel, duplicatedContent);

		});

		let originalChildren;

		if(duplicatedContent.page_type === "set") {

			// if set is closed, also close the duplicated set
			if(getClosedSets().includes(originalContent.id)) {
				updateClosedSetsList('add', duplicatedContent.id)
			}

			// create an entry in the byParent structure reducers
			const { CRDTItem: structureByParentCRDT } = getCRDTItem({
				reducer: 'structure',
				item: 'byParent'
			});

			structureByParentCRDT.set(duplicatedContent.id, new Y.Array());

			originalChildren = await getAllItemsInSet(originalContent.id);
			
			const lastChild = _.last(originalChildren) || originalContent.id;

			// insert new set after the last child of the original set
			if(!options.hasOwnProperty('customSortIndex')) {
				options.customSortIndex = sortMapCRDT.get(lastChild) + 1;
			}

		}

		// insert the content in to the site's structure by providing `toItem` and `toParent`
		// arguments only. This'll result in a insert operation
		await sortContent(
			undefined, 
			{
				id: duplicatedContent.id, 
				content: newContentModel.toJSON()
			}, 
			undefined, 
			parentId,
			// insert at the specified sort index or after the cloned item
			{
				customSortIndex: options.customSortIndex ?? sortMapCRDT.get(originalContent.id) + 1,
				insertAfter: options.insertAfter
			}
		)

		// only run this if we're in the top level call in case we run set duplication
		if(options.isNestedDuplicationCall !== true) {

			if(duplicatedContent.page_type === "set") {

				// when cloning a set, we now have to also clone all the children
				let i;
				let lastSortIndex = options.customSortIndex;

				const oldToNewParentMap = {
					[originalContent.id]: duplicatedContent.id
				};

				for(i = 0; i < originalChildren.length; i++) {

					const originalChild = state.pages.byId[originalChildren[i]] || state.sets.byId[originalChildren[i]];

					// get the original parent
					const originalParentOfChild = selectors.getItemParentId(state, originalChild.id);
					const duplicatedParentOfChild = oldToNewParentMap[originalParentOfChild];

					const duplicatedChild = await duplicateContent(originalChild.id, {
						customParentId: duplicatedParentOfChild,
						customSortIndex: ++lastSortIndex,
						title: originalChild.title,
						isNestedDuplicationCall: true
					});

					if(duplicatedChild.page_type === "set") {
						oldToNewParentMap[originalChild.id] = duplicatedChild.id;
					}

				}

			}

			// all done, emit the event for the original duplication call
			window.requestAnimationFrame(()=>{
				pageListEvents.emit('new-content-created', [duplicatedContent.id, originalContent.id]);
			})

		}

		// resume undo
		globalUndoManager.resume();

		return duplicatedContent;


	} catch(e) {
		
		console.error(e);
		Sentry.captureException(e);

	}

	globalUndoManager.resume();

}

export const pinContent = async (id, enable, options = {}) => {

	console.log(enable ? 'pin' : 'unpin', id);

	await initializeSiteStructure();

	globalUndoManager.pause();

	try {

		const { CRDTItem: draftPage } = getCRDTItem({
			reducer: 'pages.byId',
			item: id
		});

		const originalContent = draftPage.toJSON();
		const state = store.getState();

		if(enable) {

			// if pinning, the page cannot be a homepage as well
			if(state.site.homepage_id === id) {
				await setHomePage(id, false, 'desktop', options);
			}

			if(state.site.mobile_homepage_id === id) {
				await setHomePage(id, false, 'mobile', options);
			}

			// pinned cannot be overlaid as well
			if(originalContent?.overlay === true) {
				await overlayContent(id, false, options);
			}

			// fix old issue where pin options look like {0: []} preventing
			// us from setting defaults because the object would not be empty
			if(
				typeof originalContent.pin_options === 'object'
				&& originalContent.pin_options[0] !== undefined
			) {
				delete originalContent.pin_options[0];
			}

			// make sure there's default settings
			if(!originalContent.pin_options || _.isEmpty(originalContent.pin_options)) {
				applyChangesToYType(draftPage, {
					pin_options: pinDefaults
				});
			}

			// delete {0: []} from pin options if lingering in CRDT data
			if(
				draftPage.get('pin_options') instanceof Y.Map
				&& draftPage.get('pin_options').has('0')
			) {
				draftPage.get('pin_options').delete('0')
			}

			draftPage.set('pin', true);

		} else {
			
			draftPage.set('pin', false);

		}

		if(options.preventSort !== true) {
			const parentId = selectors.getItemParentId(state, id);

			// pinning or unpinning will likely affect the indexes. Run a sort to 
			// update the proper fields
			await sortContent(
				{
					id, 
					content: originalContent
				}, 
				{
					id, 
					content: draftPage.toJSON()
				}, 
				parentId, 
				parentId
			);
		}

	} catch(e) {
		console.error(e);
		Sentry.captureException(e);
	}

	globalUndoManager.resume();

}

export const overlayContent = async (id, enable, options = {}) => {

	await initializeSiteStructure();

	globalUndoManager.pause();

	try {

		const { CRDTItem: draftPage } = getCRDTItem({
			reducer: 'pages.byId',
			item: id
		});

		const originalContent = draftPage.toJSON();
		const state = store.getState();

		if(enable) {

			// if overlaying, the page cannot be a homepage as well
			if(state.site.homepage_id === id) {
				await setHomePage(id, false, 'desktop', options);
			}

			if(state.site.mobile_homepage_id === id) {
				await setHomePage(id, false, 'mobile', options);
			}

			// overlay cannot be pinned as well
			if(originalContent?.pin === true) {
				await pinContent(id, false, options);
			}
			
			// make sure there's default settings
			if(!originalContent.overlay_options || _.isEmpty(originalContent.overlay_options)) {
				console.log('loading defaults', overlayDefaults)
				applyChangesToYType(draftPage, {
					overlay_options: {
						...overlayDefaults,
						closeOnClickout: true,
						closeOnNavigate: true,
					}
				});
			}

			draftPage.set('overlay', true);

		} else {
			
			draftPage.set('overlay', false);

		}

		if(options.preventSort !== true) {
			const parentId = selectors.getItemParentId(state, id);

			// pinning or unpinning will likely affect the indexes. Run a sort to 
			// update the proper fields
			await sortContent(
				{
					id, 
					content: originalContent
				}, 
				{
					id, 
					content: draftPage.toJSON()
				}, 
				parentId, 
				parentId
			);
		}

	} catch(e) {
		console.error(e);
		Sentry.captureException(e);
	}

	globalUndoManager.resume();

}

export const setHomePage = async (id, enable, type, options = {}) => {

	console.log(enable ? 'set' : 'unset', 'hompage', id, type);

	await initializeSiteStructure();

	globalUndoManager.pause();

	try {

		const { CRDTItem: draftSiteModel } = getCRDTItem({
			reducer: 'site'
		});

		const state = store.getState();
		const homepageField = type === 'mobile' ? 'mobile_homepage_id' : 'homepage_id';

		if(!id) {

			if(enable === false && state.site[homepageField] != null) {
				// just remove the old homepage
				await setHomePage(state.site[homepageField], false, type, options);
			}

			globalUndoManager.resume();

			return;
		}

		let content = state.pages.byId[id] || state.sets.byId[id];

		if( enable ){

			// if pinning, the page cannot be a homepage as well
			if(content?.pin === true) {
				await pinContent(id, false, options);
			}

			// homepage cannot be overlaid as well
			if(content?.overlay === true) {
				await overlayContent(id, false, options);
			}

			// if another page is homepage, un-homepage that
			if(state.site[homepageField] != null) {
				await setHomePage(state.site[homepageField], false, type, options);
			}
			

			draftSiteModel.set(homepageField, id);

		} else {
			
			draftSiteModel.set(homepageField, null);

		}

	} catch(e) {

		console.error(e);
		Sentry.captureException(e);

	}

	globalUndoManager.resume();

}

export const displayContent = async (id, enable) => {
	
	console.log(enable ? 'display' : 'hide', id);

	await initializeSiteStructure();

	globalUndoManager.pause();

	try {

		const { CRDTItem: draftPage } = getCRDTItem({
			reducer: 'pages.byId',
			item: id
		});

		const originalContent = draftPage.toJSON();
		const state = store.getState();

		draftPage.set('display', enable);

		const parentId = selectors.getItemParentId(state, id);

		// Changing display on content will affect the indexes. Run a sort to 
		// update the proper fields
		await sortContent(
			{
				id, 
				content: originalContent
			}, 
			{
				id, 
				content: draftPage.toJSON()
			}, 
			parentId, 
			parentId
		)

	} catch(e) {

		console.error(e);
		Sentry.captureException(e);

	}

	globalUndoManager.resume();

}

// const generatePages = async (amount) => {
// 
// 	console.log('generating', amount, 'pages...');
// 
// 	for(let i = amount; i >= 0; i--) {
// 
// 		const newPageId = await createNewContent('page', `generated page ${i}`);
// 
// 		// grab the page and set some content
// 		const {CRDTItem: CRDTPage} = getCRDTItem({
// 			reducer: 'pages.byId',
// 			item: newPageId
// 		});
// 
// 		CRDTPage.set('content', new Y.XmlFragment());
// 
// 		for(let i = 0; i < 50; i++) {
// 
// 			CRDTPage.get('content').insert(0, [new Y.XmlText(crypto.randomUUID())])
// 
// 		}
// 
// 		console.log('Created page... waiting');
// 		await new Promise(resolve => setTimeout(resolve, 100));
// 		console.log('Continue...');
// 
// 	}
// 
// 	console.log('done...');
// 
// }
// 
// window.generatePages = generatePages;

export const setStackScrollSnapOptions = async (id, key="snap_pages", val=false) => {


	await initializeSiteStructure();

	globalUndoManager.pause();

	try {

		const { CRDTItem: draftSet } = getCRDTItem({
			reducer: 'sets.byId',
			item: id
		});

		applyChangesToYType(draftSet, {
			page_design_options: {
				[key]: val
			}
		});		


	} catch(e) {

		console.error(e);
		Sentry.captureException(e);

	}

	globalUndoManager.resume();


}

export const setStack = async (id, enable) => {

	console.log(enable ? 'stack' : 'unstack', id);

	await initializeSiteStructure();

	globalUndoManager.pause();

	try {

		if(id === 'root') {

			const state = store.getState();
			const homepageId = state.site?.homepage_id;
			const mobileHomepageId = state.site?.mobile_homepage_id;

			// root stacking needs some special stuff

			// remove homepage and store the last used homepage id for retrieval when 
			// unstacking root. Also, delete this value in `setHomePage` so we don't reset a stale homepage

			const { CRDTItem: adminStateCRDT } = getCRDTItem({
				reducer: 'adminState',
				item: 'crdt'
			});

			if(enable) {

				// delete homepage and store in adminstate.crdt
				if(homepageId) {
					adminStateCRDT.set('homepage-before-feed', homepageId);
					await setHomePage(homepageId, false, 'desktop');
				}

				if(mobileHomepageId) {
					adminStateCRDT.set('mobile-homepage-before-feed', mobileHomepageId);
					await setHomePage(mobileHomepageId, false, 'mobile');
				}

			} else {

				// restore homepage from adminstate.crdt and delete from there
				if(adminStateCRDT.has('homepage-before-feed')) {
					const homepageToRestore = adminStateCRDT.get('homepage-before-feed');
					adminStateCRDT.delete('homepage-before-feed');
					await setHomePage(homepageToRestore, true, 'desktop');
				}

				if(adminStateCRDT.has('mobile-homepage-before-feed')) {
					const homepageToRestore = adminStateCRDT.get('mobile-homepage-before-feed');
					adminStateCRDT.delete('mobile-homepage-before-feed');
					await setHomePage(homepageToRestore, true, 'mobile');
				}

			}

		}

		const { CRDTItem: draftSet } = getCRDTItem({
			reducer: 'sets.byId',
			item: id
		});

		draftSet.set('stack', enable || !draftSet.get('stack'));

	} catch(e) {

		console.error(e);
		Sentry.captureException(e);

	}

	globalUndoManager.resume();


}

export const sortContent = async (fromItem, toItem, fromParentId, toParentId, options = {}) => {

	if (!fromItem && !toItem) {
		return;
	}

	const {
		customSortIndex = 0, 
		// By default we insert before the `toItem`. Use this to insert the item
		// after the `toItem` instead.
		insertAfter = false
	} = options;

	console.log('sort', fromItem?.id, 'to', toItem?.id, 'parent:', fromParentId, '->', toParentId, 'options', options);

	// console.log('before');
	// window.logSiteStructure();
	
	try {

		await initializeSiteStructure();

		globalUndoManager.pause();

		const { CRDTItem: sortMapCRDT } = getCRDTItem({
			reducer: 'structure',
			item: 'bySort'
		});

		const { CRDTItem: parentMapCRDT } = getCRDTItem({
			reducer: 'structure',
			item: 'byParent'
		});

		const { CRDTItem: indexMapCRDT } = getCRDTItem({
			reducer: 'structure',
			item: 'indexById'
		});

		ydoc.transact(() => {

			// When we pass the same content as fromItem and toItem we only want update indexes and not attempt
			// to sort or reparent
			const updateInPlace = !!(fromItem && toItem && fromItem.id === toItem?.id);

			let fromSortIndex = null;
			let draggedItemIds = null;
			
			let itemToInsert;

			if(updateInPlace) {
				// when updating the item we always use toItem
				itemToInsert = toItem;
			} else {
				// for insert-only operations there's no fromItem.
				itemToInsert = fromItem ? fromItem : toItem;
			}

			// check if we need to update indexes for this insertion. If there's no
			// content model, determine indexability based on it's current index status
			const insertedItemIsIndexable = itemToInsert.content ? helpers.contentIsIndexable(itemToInsert.content) : indexMapCRDT.get(itemToInsert.id) !== null;
			const fromItemIsIndexable = fromItem && (fromItem.content ? helpers.contentIsIndexable(fromItem.content) : indexMapCRDT.get(fromItem.id) !== null);

			//console.log('from sort index', sortMapCRDT.get(String(fromItem?.id)), 'to sort index', sortMapCRDT.get(String(toItem?.id)))

			// remove original
			if(fromItem) {

				if(!updateInPlace) {

					draggedItemIds = [
						fromItem.id,
						...getAllItemsInSet(fromItem.id)
					]

					fromSortIndex = sortMapCRDT.get(String(fromItem.id));

					// delete all items from the sortMap
					draggedItemIds.forEach(itemId => {
						sortMapCRDT.delete(String(itemId));
					});
					
					// update sorts 
					for (let [itemID, sortIndex] of sortMapCRDT) {

						if(sortIndex >= fromSortIndex) {
							sortMapCRDT.set(itemID, sortIndex - draggedItemIds.length);
						}

					}

				}

				const oldParentChildren = parentMapCRDT.get(String(fromParentId));

				if(oldParentChildren) {

					const currentIndex = indexMapCRDT.get(String(fromItem.id));

					// remove index
					if(!updateInPlace) {
						indexMapCRDT.delete(String(fromItem.id));
					}

					let indexToDeleteFromParent = null;

					oldParentChildren.forEach((childId, index) => {

						if(updateInPlace && (insertedItemIsIndexable === fromItemIsIndexable)) {
							// we're updating in place and the index will not be affected.
							return;
						}

						if(childId === fromItem.id) {
							if(!updateInPlace) {
								// Mark item for deletion. Don't delete here because
								// it can affect the current loop
								indexToDeleteFromParent = index;
							}
						} else {

							const childIndex = indexMapCRDT.get(String(childId));

							// the removed item is/was indexable so it affects the index of all succeeding items
							if(childIndex !== null && fromItemIsIndexable && childIndex > currentIndex) {
								// move index down by one
								indexMapCRDT.set(String(childId), childIndex - 1);
							}

						}

					});

					// delete after we loop
					if(indexToDeleteFromParent !== null) {
						oldParentChildren.delete(indexToDeleteFromParent, 1);
					}

					// we've removed an indexable item from a set. Update the
					// page_count field so frontend can accurately determine
					// what content to paginate.
					if(fromItemIsIndexable && !updateInPlace) {

						// grab the old parent set from the CRDT
						const { CRDTItem: oldParentSet } = getCRDTItem({
							reducer: 'sets.byId',
							item: fromParentId
						});

						oldParentSet.set('page_count', oldParentSet.get('page_count') - 1);

					}

					//console.log('remove', fromItem, 'from', fromParentId)

				} else {
					console.log('Unable to remove', fromItem.id, 'from', fromParentId);
				}

			}

			// console.log('in between');
			// window.logSiteStructure();

			// insert new
			if(toItem) {

				if(!updateInPlace) {
					// make space for the new item
					let toSortIndex = sortMapCRDT.get(String(toItem.id)) || customSortIndex || 0;

					if(insertAfter === true) {
						
						toSortIndex += 1;

						if(toItem.content.page_type === "set" && toItem.isClosed === true) {
							// When inserting behind a set which is closed, it needs to go after it's last child
							toSortIndex += getAllItemsInSet(toItem.id).length;
						}

					}

					//console.log('insert item at index', toSortIndex);

					if(draggedItemIds === null) {
						draggedItemIds = [itemToInsert.id];
					}

					for (let [itemID, sortIndex] of sortMapCRDT) {

						if(sortIndex >= toSortIndex) {
							// increment the sort to make space for the newly inserted items
							sortMapCRDT.set(itemID, sortIndex + draggedItemIds.length);
						}

					}

					// set the new sort index for the items that got inserted
					draggedItemIds.forEach((itemId, index) => {
						sortMapCRDT.set(String(itemId), toSortIndex + index);
					});

				}

				const newParentChildren = parentMapCRDT.get(String(toParentId));

				if(newParentChildren) {
					
					if(!updateInPlace) {
						// add the inserted item to it's new set
						newParentChildren.push([itemToInsert.id]);
					}

					//console.log('add', itemToInsert, 'to', toParentId);

					let indexOffset = 0;

					if(!updateInPlace) {
						
						if(insertedItemIsIndexable) {
							// inserting something that is indexable, one index is added
							indexOffset = 1;
						} else {
							// not indexable. Set index to null
							indexMapCRDT.set(String(itemToInsert.id), null);
						}

					} else if(updateInPlace) {

						if(indexMapCRDT.get(String(itemToInsert.id)) !== null && insertedItemIsIndexable === false) {
							
							// The item we're updating went from indexable to non-indexable
							indexOffset = -1;

							// set it's index to null
							indexMapCRDT.set(String(itemToInsert.id), null);

						} else if(indexMapCRDT.get(String(itemToInsert.id)) === null && insertedItemIsIndexable === true) {
							
							// The item we're updating went from non-indexable to indexable
							indexOffset = 1;

							// set it's index to 0, we'll update it below
							indexMapCRDT.set(String(itemToInsert.id), 0);

						}

					}

					// the indexes for this set need to be updated because a new indexable item 
					// got inserted or an item went from indexable to non-indexable
					if(indexOffset !== 0) {

						// grab the new parent from the CRDT
						const { CRDTItem: newParentSet } = getCRDTItem({
							reducer: 'sets.byId',
							item: toParentId
						});

						// up the indexable page count for this set
						newParentSet.set('page_count', newParentSet.get('page_count') + indexOffset);

						// grab all the children contained in the new parent set
						let sortedIndexableChildren = newParentChildren.toArray();

						// filter out all non-indexable items ( non-indexable have an index of null )
						// and all items that don't have a sort index
						sortedIndexableChildren = sortedIndexableChildren.filter(childId => {
							return indexMapCRDT.get(String(childId)) !== null && sortMapCRDT.get(String(childId)) !== undefined
						})

						// sort the list according to the latest sorting order
						sortedIndexableChildren.sort((a,b) => {
							return sortMapCRDT.get(String(a)) - sortMapCRDT.get(String(b))
						});

						// once sorted the indexes are the same as the array indices
						sortedIndexableChildren.forEach((childId, newIndex) => {

							// if the new index is different, set it
							if(indexMapCRDT.get(String(childId)) !== newIndex) {
								indexMapCRDT.set(String(childId), newIndex);
							}

						});

					}

				} else {
					console.log('Unable to add', itemToInsert.id, 'to', toParentId);
				}

			}

		}, options.yTransactionOrigin || null);

	} catch(e) {

		console.error(e);
		Sentry.captureException(e);

	}

	globalUndoManager.resume();

	setTimeout(() => {
		checkSorts();
	});
	// console.log('after');
	// window.logSiteStructure();

}

export const deleteAllContent = async () => {

	await initializeSiteStructure();

	const state = store.getState();

	const contentToDelete = [
		..._.values(state.pages.byId).filter(page => page.crdt_state !== CRDTState.Deleted),
		..._.values(state.sets.byId).filter(set => set.crdt_state !== CRDTState.Deleted && set.id !== 'root')
	].sort((a,b) => {
		return state.structure.bySort[a.id] - state.structure.bySort[b.id];
	});

	// wipe site structure (do this first so the page list renders empty)
	ydoc.transact(() => {

		const { CRDTItem: structureCRDT } = getCRDTItem({
			reducer: 'structure',
			publishable: false
		});

		structureCRDT.set('byParent', new Y.Map);
		structureCRDT.set('bySort', new Y.Map);
		structureCRDT.set('indexById', new Y.Map);

		// add root defaults
		structureCRDT.get('byParent').set('root', new Y.Array);

		const { CRDTItem: rootSetCRDTItem } = getCRDTItem({
			reducer: 'sets.byId',
			item: 'root'
		});

		rootSetCRDTItem.set('page_count', 0);

	});

	// sequentually delete all sets and pages
	await contentToDelete.reduce((currentDeletePromise, nextContent, index) => {
		
		return currentDeletePromise.finally(async () => {

			// wait a bit as to not generate huge updates that the
			// server will reject
			await new Promise(resolve => setTimeout(resolve, 50));

			// return the delete promise
			return deleteContent(nextContent.page_type, nextContent.id, {
				preventSort: true
			});

		});

	}, Promise.resolve());

}

const initializeSiteStructure = async () => {

	const { CRDTItem: sortMapCRDT } = getCRDTItem({
		reducer: 'structure',
		item: 'bySort',
		publishable: false
	});

	const { CRDTItem: parentMapCRDT } = getCRDTItem({
		reducer: 'structure',
		item: 'byParent',
		publishable: false
	});

	const { CRDTItem: indexMapCRDT } = getCRDTItem({
		reducer: 'structure',
		item: 'indexById',
		publishable: false
	});

	const { CRDTItem: liveIndexMapCRDT } = getCRDTItem({
		reducer: 'structure',
		item: 'liveIndexes',
		publishable: false
	});

	await liveSorts.then((liveSortData) => {

		if(
			liveSortData === undefined
			|| ydoc.get('draft-store').get('structure')?.get('initialized') === true
		) {
			// we've already initialized. Don't go any further.
			return;
		}

		if(!_.isArray(liveSortData?.data)) {
			throw 'Invalid live sort data found. Unable to initialize site structure.';
		}

		console.log('initializing structure')

		// clear the live sort data so we only run this once.
		liveSorts = Promise.resolve();

		const { CRDTItem: draftPages } = getCRDTItem({
			reducer: 'pages.byId',
			readOnly: true,
			publishable: false
		});

		ydoc.transact(() => {
			
			// loop over the live sorts, indexes and parent maps and 
			// inject them into the draft CRDT.
			liveSortData.data.forEach(({id, index, set_id, sort, purl}) => {

				if(
					id === 'root'
					|| (
						draftPages.has(String(id)) 
						&& draftPages.get(String(id)).get('crdt_state') === CRDTState.Deleted
					)
				) {
					// do not attempt to inject root or deleted pages into the structure CRDT
					return;
				}

				if(sortMapCRDT.get(String(id)) !== sort) {
					// set sort for this id
					sortMapCRDT.set(String(id), sort);
				}

				if(indexMapCRDT.get(String(id)) !== index) {
					// set index for this id
					indexMapCRDT.set(String(id), index);
				}

				if(index !== null && liveIndexMapCRDT.get(String(id)) !== index) {
					// set index for this id
					liveIndexMapCRDT.set(String(id), `${set_id}/${index}`);
				}

				// create parent entry if it doesn't exist yet.
				if(!parentMapCRDT.has(String(set_id))) {
					parentMapCRDT.set(String(set_id), new Y.Array());
				}
				
				// map id to it's parent, check if it exists first to prevent dupes
				if(!parentMapCRDT.get(String(set_id)).toArray().includes(id)) {
					parentMapCRDT.get(String(set_id)).push([id]);
				}

			});

			// indicate the CRDT contains all sort data from now on.
			ydoc.get('draft-store').get('structure').set('initialized', true);

		}, YTransactionTypes.NotUndoableNotPublishable);
		
	});

}

const getAllItemsInSet = (id) => {

	let items = [];

	const { CRDTItem: parentMapCRDT } = getCRDTItem({
		reducer: 'structure',
		item: 'byParent',
		readOnly: true
	});

	const { CRDTItem: sortMapCRDT } = getCRDTItem({
		reducer: 'structure',
		item: 'bySort',
		readOnly: true
	});

	const children = parentMapCRDT.get(String(id))?.toArray();

	if(!children) {
		return items;
	}

	// ensure sort order
	children
		.sort((a,b) => sortMapCRDT.get(a) - sortMapCRDT.get(b))
		.forEach(id => {

			items.push(id);

			// recurse when we find a nested set
			if(parentMapCRDT.has(id)) {
				items = [...items, ...getAllItemsInSet(id)];
			}

		});

	return items;

}

export const getClosedSets = () => {

	let closedSets = [];

	try {
		JSON.parse(localStorage.getItem("closed-sets")).forEach(id => closedSets.push(id));
	} catch(e) {}

	return closedSets;

}

const updateClosedSetsList = (type, setId) => {

	// Make set closed by default
	let closedSets = getClosedSets();

	if(closedSets.includes(setId) && type === 'remove') {
		closedSets.splice(closedSets.indexOf(setId), 1);
	} 

	if(!closedSets.includes(setId) && type === 'add') {
		closedSets.push(setId);
	}

	pageListEvents.emit('closed-sets-updated', [closedSets]);

	// set local storage
	localStorage.setItem("closed-sets", JSON.stringify(closedSets));

}


export const getFirstPageInSet = async (setId, options = {}) => {

	// grab the entire site structure
	await initializeSiteStructure();

	const state = store.getState();

	const sortedSetContents = getAllItemsInSet(setId);

	for(let i = 0; i < sortedSetContents.length; i++) {

		// we only care about pages at this point.
		const isSet = state.structure.byParent.hasOwnProperty(sortedSetContents[i]);

		// we want to edit the first indexable page we find
		if(!isSet) {

			if(options.indexable === true && state.structure.indexById[sortedSetContents[i]] === null) {
				// page is not indexable. Keep looking...
				continue;
			}
			
			return sortedSetContents[i];

		}

	}

}
